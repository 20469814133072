<section *ngIf="inEditModeOr(hasDisplayName)">
    <div class="avatar">
        <div class="dot"></div>
        <p class="first-char">{{ displayNameFirstCharacter }}</p>
    </div>
    <div>
        <ng-container *ngIf="inEditModeOr(hasDisplayName)">
            <p class="name">
                <ng-container *ngIf="hasFieldOrEditable(profileMessage)">
                    <span *scRichText="profileMessage"></span>
                </ng-container>
                <span> {{ displayName }}</span>
            </p>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.name)">
            <a *scRichText="rendering.fields.name" class="my-account" [href]="accountData.url" (click)="closeMobileNav()"></a>
        </ng-container>
    </div>
</section>

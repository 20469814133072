import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsServerService {
    /* To Fake Analytics Script */
    public loadAnalytics(): Promise<void> {
        return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => {
            resolve();
        });
    }

    /* Send Custom Pageload events */
    public updateCustomParams(_customParams: TDigitalData, _sendCustomLoadEvent?: boolean): void {
        // nothing to do on the server
    }
}

import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { StateService } from '@uirouter/angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { SectionContainer } from 'core/constants';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-plans-comparison',
    styleUrls: [ './plans-comparison.scss' ],
    templateUrl: './plans-comparison.html'
})
export class MarketingPlansComparisonComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    @Input() public rendering: ComponentRendering;

    public pageData: PlansComparisonPageData;
    public icon: MediaImageOptions;
    public overview: boolean;
    public isPlansOverview: boolean;
    public isPlansPage: boolean;
    public sectionContainer: string;

    private state: StateService;

    constructor(injector: Injector, state: StateService) {
        super(injector);

        Object.assign(this, { state });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PlansComparisonPageData>(this.rendering.fields);
        this.sectionContainer = this.pageData.sectionContainer;

        if (this.sectionContainer === SectionContainer.PLANS_OVERVIEW_SECTION) {
            this.isPlansOverview = true;
            this.isPlansPage = false;
        } else if (this.sectionContainer === SectionContainer.PLANS_PAGE_SECTION) {
            this.isPlansOverview = false;
            this.isPlansPage = true;
        }

        if (this.pageData.icon) {
            this.icon = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        }  
    }

    public ngAfterViewInit(): void {
        if (document.getElementById(this.state.params.data_option)) {
            document.getElementById(this.state.params.data_option).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    }

    public generateImageFromCms(image: CmsMediaOptionsImage): MediaImageOptions {
        return Cloudinary.generateMediaOptionsFromCms(image);
    }

}

<section *ngIf="pageData.isBanner" class="steps-banner-section">
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.bannerHeadline)">
        <div class="steps-banner-header left" *scRichText="rendering.fields.bannerHeadline"></div>
    </ng-container>
    <div class="swiper-container">
        <ng-container *ngIf="hasPlaceholder(rendering, 'stepsBannerBlock')">
                <div class="swiper-wrapper" sc-placeholder name="stepsBannerBlock"
                    [rendering]="rendering" [inputs]="inputs">
                </div>
        </ng-container>
    </div>
</section>

<section *ngIf="!pageData.isBanner">
    <div class="steps-header-section">
        <div class="steps-content">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.bannerHeadline)">
                <div class="steps-header" *scRichText="rendering.fields.bannerHeadline"></div>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="hasPlaceholder(rendering, 'stepsBannerBlock')">
        <div class="swiper-container steps-overview-section">
            <div class="swiper-wrapper" sc-placeholder name="stepsBannerBlock" [rendering]="rendering"
                [inputs]="inputs"></div>
        </div>
    </ng-container>
</section>
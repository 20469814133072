import { Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'step-module',
    styleUrls: [ './steps.scss' ],
    templateUrl: './steps.html'
})
export class MarketingStepsComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: MarketingStepPageData;
    public inputs: StepInput;

    constructor(injector: Injector, private _ref: ElementRef) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingStepPageData>(this.rendering.fields);

        this.inputs = {
            isBanner: this.pageData.isBanner
        };

        var host = this._ref.nativeElement;
        
        if (this.pageData.isBanner) {
            host.style.background = this.pageData.background;
        }
    }
}

<section *ngIf="isBanner" class="step-section">
    <div class="banner-number-circle">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.stepNumber)">
            <div class="banner-number" *scRichText="rendering.fields.stepNumber"></div>
        </ng-container>
    </div>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.stepHeader)">
        <div class="step-header" *scRichText="rendering.fields.stepHeader"></div>
    </ng-container>
</section>

<section *ngIf="!isBanner">
    <div class="overview-number">
        <div class="number-circle" [ngClass]="{'step-one': stepOne, 'step-two': stepTwo, 'step-three': stepThree}">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.stepNumber)">
                <div class="number" *scRichText="rendering.fields.stepNumber"></div>
            </ng-container>
        </div>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.stepHeader)">
                <div class="step-overview-header" *scRichText="rendering.fields.stepHeader"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.stepBody)">
                <div class="step-overview-body" *scRichText="rendering.fields.stepBody"></div>
            </ng-container>
            <span>
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.stepCta)"
                    [field]="rendering.fields.stepCta" [overview]="true">
                </xm-generic-link>
            </span>
        </div>
    </div>
</section>
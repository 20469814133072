<button type="button"
        class="hamburger"
        aria-label="Navigation menu"
        (click)="toggleMobileNav()"
        [attr.aria-expanded] ="isMobileNavOpen">
    <span [ngClass]="{ 'close-hamburger': isMobileNavOpen }" class="hamburger-stripes"></span>
</button>

<div class="mobile-menu-list" [ngClass]="{ 'pages-visible': isMobileNavOpen, 'not-top': editorActive }">
    <div class="mobile-menu-items">
        <xm-nav-profile class="easing" *ngIf="pageData.myAccount || myAccountRendering" [profileMessage]="rendering.fields.profileMessage" [rendering]="rendering.fields.myAccount || myAccountRendering" [displayName]="displayName"></xm-nav-profile>
        <div *ngFor="let mobileMenuItem of rendering.fields.mobileMenuItems" class="mobile-menu-item easing">
            <xm-nav-menu-items [rendering]="mobileMenuItem" (toggleMobileNav)="toggleMobileNav()"></xm-nav-menu-items>
        </div>
    </div>
    <ng-container *ngIf="inEditModeOr(!!rendering.fields.myAccount && !!rendering.fields.logout)">
        <div class="lower-container">
            <div class="lower-item" *ngIf="inEditModeOr(!hasDisplayName)">
                <a *scRichText="getFieldItem(rendering.fields, 'myAccount')?.fields.name" class="xm-btn-link-light cta" [href]="loginInfo && loginInfo.url" (click)="toggleMobileNav()"></a>
            </div>
            <div class="lower-item" *ngIf="inEditModeOr(hasDisplayName || isLoggedIn)">
                <button *scRichText="getFieldItem(rendering.fields, 'logout')?.fields.name" (click)="logout()" class="xm-btn-link-light cta"></button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="inEditModeOr(quickPayRendering && isLoggedIn)">
        <div class="lower-container">
            <div class="lower-item" *ngIf="quickPayRendering">
                <a *scRichText="quickPayRendering.fields.name" class="xm-btn-link-light cta" [href]="getFieldValue(quickPayRendering.fields, 'url')" (click)="toggleMobileNav()"></a>
            </div>
            <div class="lower-item" *ngIf="logoutRendering">
                <button *scRichText="logoutRendering.fields.name" (click)="logout()" class="xm-btn-link-light cta"></button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn && quickPayRendering">
        <div class="lower-container">
            <div class="lower-item" *ngIf="loginRendering">
                <a *scRichText="loginRendering.fields.name" class="xm-btn-link-light cta" [href]="getFieldValue(loginRendering.fields, 'url')" (click)="toggleMobileNav()"></a>
            </div>
            <div class="lower-item" *ngIf="quickPayRendering">
                <a *scRichText="quickPayRendering.fields.name" class="xm-btn-link-light cta" [href]="getFieldValue(quickPayRendering.fields, 'url')" (click)="toggleMobileNav()"></a>
            </div>
        </div>
    </ng-container>
</div>

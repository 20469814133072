import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';
import { PageName, PlanType } from 'core/constants';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-plan-features',
    styleUrls: [ './plan-features.scss' ],
    templateUrl: './plan-features.html'
})
export class MarketingPlanFeaturesComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: PlanFeatures;
    public sectionIcon: MediaImageOptions;
    public icon: MediaImageOptions;
    public dollarSymbol: string;
    public rateplanPrice: string;
    public isPlanPage: boolean;
    public isOverViewPage: boolean;
    public planFeatureTwo: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PlanFeatures>(this.rendering.fields);

        this.dollarSymbol = this.pageData.ratePlanPrice?.charAt(0);
        this.rateplanPrice = this.pageData.ratePlanPrice?.slice(1);
        this.isPlanPage = this.pageData.pageName === PageName.PLAN;
        this.isOverViewPage = this.pageData.pageName === PageName.OVERVIEW;
        this.planFeatureTwo = this.pageData.featureClass === PlanType.UNLIMITED_PREMIUM;

        if (this.pageData.sectionIcon) {
            this.sectionIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.sectionIcon);
        }
    }

    public generateImageFromCms(image: CmsMediaOptionsImage): MediaImageOptions {
        return Cloudinary.generateMediaOptionsFromCms(image);
    }
}

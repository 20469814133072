<section #parentRef [ngClass]="{ 'overview-section': isOverviewPage }" >
    <div class="image-container" *ngIf="!isOverviewPage">
        <xm-media-image aria-hidden="true" [imageOptions]="icon" class="icon"></xm-media-image>
    </div>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 class="header" *scRichText="rendering.fields.header" [ngClass]="{ 'overview-header': isOverviewPage }"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
        <div class="title" *scRichText="rendering.fields.title"></div>
    </ng-container>

    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body) && !isOverviewPage" class="body" [field]="rendering.fields.body"></xm-cms-text>
    
    <p class="overview-body" [innerHtml]="pageData?.body" *ngIf="isOverviewPage"></p>

    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subTitle)">
        <div class="sub-title" [ngClass]="{ 'overview-subTitle': isOverviewPage }" *scRichText="rendering.fields.subTitle"></div>
    </ng-container>

    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subUnderTitle)">
        <div class="sub-under-title" *scRichText="rendering.fields.subUnderTitle"></div>
    </ng-container>


    <div id="btg" *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne)" class="data-options-container btgb-container">
        <div class="image-container" *ngIf="isOverviewPage" [ngClass]="{ 'overview-image-container': isOverviewPage }" >
            <xm-media-image aria-hidden="true" [imageOptions]="icon" class="icon" [ngClass]="{ 'overview-image': isOverviewPage }"></xm-media-image>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne) || hasFieldOrEditable(rendering.fields.btgPlanSizeOneSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceOne)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeOne"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOneSuffix)">
                <sup class="suffix" *scRichText="rendering.fields.btgPlanSizeOneSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceOne)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceOne"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwo) || hasFieldOrEditable(rendering.fields.btgPlanSizeTwoSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceTwo)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwo)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeTwo"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwoSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeTwoSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceTwo)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceTwo"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThree) || hasFieldOrEditable(rendering.fields.btgPlanSizeThreeSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceThree)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThree)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeThree"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThreeSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeThreeSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceThree)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceThree"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options" *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFour) || hasFieldOrEditable(rendering.fields.btgPlanSizeFourSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceFour)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFour)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeFour"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFourSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeFourSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceFour)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceFour"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFive) || hasFieldOrEditable(rendering.fields.btgPlanSizeFiveSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceFive)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFive)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeFive"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFiveSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeFiveSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceFive)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceFive"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSix) || hasFieldOrEditable(rendering.fields.btgPlanSizeSixSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceSix)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSix)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeSix"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSixSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeSixSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceSix)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceSix"></div>
            </ng-container>
        </div>
    </div>
    <div class="data-options-container" *ngIf="!isOverviewPage">
        <div class="data-options"
            *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSizePrefix) || hasFieldOrEditable(rendering.fields.unlimitedPlanSize) || hasFieldOrEditable(rendering.fields.unlimitedPlanPrice)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSizePrefix)">
                <sup class="header prefix" *scRichText="rendering.fields.unlimitedPlanSizePrefix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSize)">
                <span class="plan-size" *scRichText="rendering.fields.unlimitedPlanSize"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanPrice)">
                <div class="plan-price" *scRichText="rendering.fields.unlimitedPlanPrice"></div>
            </ng-container>
        </div>
    </div>

    <div [ngClass]="{ 'overview-data-container': isOverviewPage }">
        <div [ngClass]="{ 'overview-div': isOverviewPage }">
            <div class="" *ngIf="isOverviewPage">
                <div class="data-options oveview-page-options">
                    <ng-container>
                        <div class="overview-image-container unlimited-image-container">
                            <xm-media-image aria-hidden="true" [imageOptions]="icon" class="overview-image"></xm-media-image>
                        </div>
                        <div class="offer-lines">
                            <p>{{ pageData?.unlimitedPlanSizePrefix }}</p>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
            <div class="data-options-container" [ngClass]="{ 'unlimited-data-container': isOverviewPage }">
                
                <div id="unlimited" class="unlimited-data-options" *ngIf="pageData.headerOne">
                    
                    <ng-container *ngIf="pageData.headerOne">
                            <div class="plan-card-container plan-card-border" [ngClass]="{ 'plan-card-overview': isOverviewPage }" id="unlimitedOne">
                                <div class="plan-size-wrapper" [ngClass]="{ 'plan-card-wrapper': isOverviewPage }">
                                    <span class="unlimited-plan-size" *ngIf="!isOverviewPage">{{ pageData.headerOne }}</span>
                                    <span class="titleUnlimited" *ngIf="isOverviewPage">{{ pageData.headerOne }}</span>
                                </div>
                                <div class="plan-includes" *ngIf="!isOverviewPage">
                                    <ul>
                                        <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesOne; let i = index">
                                            <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesOne[i].icon)">
                                            </xm-media-image>
                                            <div class="details" *scRichText="inclusion.fields.body"></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="plan-price-footer" [ngClass]="{ 'overview-plan-footer': isOverviewPage }" *ngIf="pageData.priceOne">
                                    <span class="plan-price-value" *ngIf="!isOverviewPage">{{ pageData.priceOne }} </span>
                                    <span class="overview-plan-footer" *ngIf="isOverviewPage">{{ pageData.priceOne | uppercase}} </span>
                                    <span>{{ pageData.businessBodyOne }}</span>
                                </div>
                            </div>
                    </ng-container>
                    <ng-container *ngIf="pageData.headerTwo">
                            <div class="plan-card-container plan-card-border" [ngClass]="{ 'plan-card-overview': isOverviewPage }" id="unlimitedTwo">
                                <div class="plan-size-wrapper" [ngClass]="{ 'plan-card-wrapper': isOverviewPage }">
                                    <span class="unlimited-plan-size" *ngIf="!isOverviewPage">{{ pageData.headerTwo }}</span>
                                    <span class="titleUnlimited" *ngIf="isOverviewPage">{{ pageData.headerTwo }}</span>
                                </div>
                                <div class="plan-includes" *ngIf="!isOverviewPage">
                                    <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesTwo; let i = index">
                                        <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesTwo[i].icon)">
                                        </xm-media-image>
                                        <div class="details" *scRichText="inclusion.fields.body"></div>
                                    </li>
                                </div>
                                <div class="plan-price-footer" [ngClass]="{ 'overview-plan-footer': isOverviewPage }" *ngIf="pageData.priceTwo">
                                    <span class="plan-price-value" *ngIf="!isOverviewPage">{{ pageData.priceTwo }} </span>
                                    <span class="overview-plan-footer" *ngIf="isOverviewPage">{{ pageData.priceTwo | uppercase}} </span>
                                    <span>{{ pageData.businessBodyTwo }}</span>
                                </div>
                            </div>
                    </ng-container>
                    <ng-container *ngIf="pageData.headerThree">
                        <div class="plan-card-container">
                            <div class="plan-size-wrapper">
                                <span class="unlimited-plan-size">{{ pageData.headerThree }}</span>
                            </div>
                            <div class="plan-includes">
                                <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesThree; let i = index">
                                    <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesThree[i].icon)">
                                    </xm-media-image>
                                    <div class="details" *scRichText="inclusion.fields.body"></div>
                                </li>
                            </div>
                            <div class="plan-price-footer" [ngClass]="{ 'overview-plan-footer': isOverviewPage }" *ngIf="pageData.priceThree">
                                <span class="plan-price-value">{{ pageData.priceThree }} </span><span>{{ pageData.businessBodyThree }}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            </div>
        <div class="overview-btg-container">
            <div class="btg-image-container" *ngIf="isOverviewPage">
                <div id="btgImage" class="data-options oveview-page-options">
                    <ng-container >
                        <div class="overview-image-container">
                            <xm-media-image id="btgIcon" aria-hidden="true" [imageOptions]="btgIcon" class="overview-image" [overviewImage]="true"></xm-media-image>
                        </div>
                        <div class="offer-lines btg-sub-header">
                            <p>{{ pageData?.btgSectionHeader }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="data-options-container btg-container" *ngIf="isOverviewPage">
                <ng-container *ngIf="pageData.btgHeader">
                    <div class="plan-card-container" [ngClass]="{ 'plan-card-overview': isOverviewPage }" id="btgPlan">
                        <div class="plan-card-wrapper">
                            <span id="btgHeader" class="titleUnlimited" *ngIf="isOverviewPage">{{ pageData.btgHeader }}</span>
                        </div>
                        
                        <div id="btg-plan-footer" class="plan-price-footer" [ngClass]="{ 'overview-plan-footer': isOverviewPage }" *ngIf="pageData.btgSubheader">
                            <span id="btg-plan-footer" class="overview-plan-footer">{{ pageData.btgSubheader  | uppercase }} </span>
                        </div>
                    </div>
                </ng-container> 
            </div>
        </div>
    </div>


    <div class="data-options-cta-container" [ngClass]="{ 'overview-cta-container': isOverviewPage }">
        <div class="cta-div" [ngClass]="{ 'overview-cta-div': isOverviewPage }">
            <xm-generic-link class="btg-cta" [ngClass]="{ 'option-cta': isOverviewPage }" *ngIf="hasFieldOrEditable(rendering.fields.btgCta)"
                [field]="rendering.fields.btgCta" [dataOptionCta]="true"></xm-generic-link>
        </div>
        <div class="cta-div">
            <xm-generic-link class="btg-cta" [ngClass]="{ 'option-cta': isOverviewPage }" *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)"
                [field]="rendering.fields.linkDecorationOne" [dataOptionCta]="true" [isOverviewPage]="isOverviewPage"></xm-generic-link>
        </div>
    </div>
    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.bottomSubtext)" class="bottom-subtext"
        [field]="rendering.fields.bottomSubtext"></xm-cms-text>
    <div *ngIf="hasPlaceholder(rendering, 'unlPricingModal')">
        <div sc-placeholder name="unlPricingModal" [rendering]="rendering"></div>
    </div>
    <xm-popover
    *ngIf="rendering.fields.popover && !isOverviewPage"
    class="popover-button"
    [rendering]="rendering.fields.popover"
    [parentTemplate]="parentRef"
    [customClass]="'planPage'"
    position="center">
    </xm-popover>
</section>
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Cloudinary } from 'services/cloudinary';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Decorator, LinkDecorationInfo } from 'services/decorator';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

@Component({
    selector: 'xm-nav-logo',
    styleUrls: [ './logo.scss' ],
    templateUrl: './logo.html'
})
export class MarketingNavLogoComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;
    @Output() public closeHamburgerNavFromLogo: EventEmitter<void> = new EventEmitter<void>();

    public logoImageOptions: MediaImageOptions;
    public logoLink: LinkDecorationInfo;
    public pageData: NavPageData;

    private decorator: Decorator;

    constructor(injector: Injector, decorator: Decorator) {
        super(injector);
        Object.assign(this, { decorator });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<NavPageData>(this.rendering.fields);
        this.logoImageOptions = { small: Cloudinary.generateMediaFromCms(this.pageData.xmLogo) };
        this.logoLink = this.pageData.genericXmUrl && this.pageData.genericXmUrl.url ?
            this.decorator.createSpaLinks(this.pageData.genericXmUrl.url) : { absoluteUrl: this.pageData.xmUrl };
    }

    public closeNav(): void {
        this.closeHamburgerNavFromLogo.emit();
    }
}

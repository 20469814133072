<section [ngClass]="{ 'carousel': isCarousel, 'tout-card-item': isCardMode, 'tout': !isCardMode, 'tout-card-container': isOverviewContainer, 'savings-tout-section': savingsSectionContainer }">
    <ng-container *ngIf="inEditModeOr(!showBusinessContent) && !isCardMode">
        <xm-media-image aria-hidden="true" *ngIf="image" class="image" [imageOptions]="image"></xm-media-image>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <h3 class="tout-header" *scRichText="rendering.fields.header"></h3>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
                <p class="tout-body" *scRichText="rendering.fields.body"></p>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="inEditModeOr(showBusinessContent) && !isCardMode">
        <xm-media-image aria-hidden="true" *ngIf="businessImage" class="image" [imageOptions]="businessImage"></xm-media-image>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.businessHeader)">
                <h3 class="tout-header" *scRichText="rendering.fields.businessHeader"></h3>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.businessBody)">
                <p class="tout-body" *scRichText="rendering.fields.businessBody"></p>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isCardMode">
        <xm-media-image aria-hidden="true" *ngIf="image" class="tout-card-image" [imageOptions]="image"></xm-media-image>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <h3 class="tout-card-header" *scRichText="rendering.fields.header"></h3>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
                <ul class="tout-card-body" *scRichText="rendering.fields.body">
                </ul>  
            </ng-container>
        </div>
    </ng-container>
</section>



import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';
import { SectionContainer } from 'core/constants';

@Component({
    selector: 'step-component',
    styleUrls: [ './step.scss' ],
    templateUrl: './step.html'
})
export class MarketingStepBannerComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;
    @Input() public isBanner: boolean;

    public pageData: MarketingStepCard;
    public stepOne: boolean;
    public stepTwo: boolean;
    public stepThree: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingStepCard>(this.rendering.fields);
        this.stepOne = this.pageData.stepNumber === SectionContainer.STEP_ONE;
        this.stepTwo = this.pageData.stepNumber === SectionContainer.STEP_TWO;
        this.stepThree = this.pageData.stepNumber === SectionContainer.STEP_THREE;
    }
}

export enum ParamType {
    encoded = 'encodedParam', // only one that is custom to XM
    path = 'path',
    query = 'query',
    any = 'any'
}

export enum FormType {
    blur = 'blur',
    submit = 'submit',
    default = 'change'
}

export enum API {
    TIMEOUT = 30000
}

export enum ApiChannel {
    CMS = 'CMS',
    GATEWAY = 'GATEWAY',
    COVERAGE = 'COVERAGE',
    PCAT = 'PCAT',
    SESSION_API = 'SESSION_API',
    BOOTSTRAP_API = 'BOOTSTRAP_API',
}

export enum BreakpointWidth {
    SMALL = 375,
    MEDIUM = 768,
    LARGE = 960,
    XLARGE = 1440
}

export enum ImageFit {
    HEIGHT = 'height',
    WIDTH = 'width',
    CONTAIN = 'contain'
}

export enum UserState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    NON_PAY_SUSPENDED = 'NON_PAY_SUSPENDED',
    NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
    CLOSED = 'CLOSED',
    HOTLINED = 'HOTLINED'
}

export enum CustomSitecoreFieldType{
    DROP_LIST = 'Droplist'
}

// const UrlParams = {
//     START: "?",
//     SPLIT: "&",
//     EQUIV: "=",
//     HASH: "#"
// }

export enum TransitionHooks {
    START = 'transitionStart',
    SUCCESS = 'transitionSuccess',
    PARAMS = 'paramsChanged'
}

export const BUTTON_LOADER_CLASS: string = 'is-loading';

export const CONFIG_TOKEN: string = 'CONFIG_TOKEN';
export const MILLISECOND_MULTIPLIER: number = 1000;

export enum MouseEventType {
    MOUSE_ENTER = 'mouseenter',
    MOUSE_LEAVE = 'mouseleave'
}

export enum FocusEventType {
    FOCUS_IN = 'focusin',
    FOCUS_OUT = 'focusout'
}

export enum Height {
    NAV_BAR = 56,
    TAB_BAR_SMALL = 37,
    TAB_BAR_LARGE = 50
}

export enum KeyboardEventKeys {
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ENTER = 'Enter',
    SPACE = ' ',
    ESCAPE = 'Escape',
    HOME = 'Home',
    END = 'End',
    TAB = 'Tab',
    BACKSPACE = 'Backspace',
    DELETE = 'Delete'
}

export const MARKETING_PARAMS: string[] = [
    'INTCMP',
    'CMP',
    'data_option'
];

export const CAMPAIGN_ID: string = 'CAMPAIGN_ID';

export const AUTH_COOKIE_NAME: string = 'isAuth';

export const AUTH_CB_UCID: string = 'cb_ucid';

export enum AuthCookieValue {
    SET = '1',
    UNSET = '0'
}

export enum StorageToken {
    CIMA = 'xm-access-token',
    TPS = 'TPS',
    SAVED_STATE = 'XM_SAVED_STATE',
    VISITOR_SESSION_ID = 'VISITOR_SESSION_ID',
    CIMA_TOKEN = 'cimaToken'
}

export enum AnimationStates {
    IDLE = 'idle',
    FINISHED = 'finished',
    PAUSED = 'paused',
    RUNNING = 'running'
}

export enum NavItemTypes {
    CHAT = 'CHAT',
    CART = 'CART',
    SEPARATOR = 'SEPARATOR',
    LOGOUT = 'LOGOUT',
    LOGIN = 'LOGIN',
    QUICKPAY = 'QUICKPAY'
}

export const TARGET_PAGE: string = 'target';

export enum PlanSku {
    SMART_WATCH_DATA = '8795486',
    BY_THE_GIG = '8420046',
    UNLIMITED = '8410397',
    FINANCED = 'RECURRING',
    PAY_IN_FULL = 'ONETIME',
    NO_XMPP = 'NO_XMPP'
}

export enum PromotionType {
    PERCENTAGE = 'PERCENTAGE',
    DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
    GIFT_CARD = 'GIFT_CARD',
    SUBSIDY = 'SUBSIDY',
    EXTENDED_PROMO = 'EXTENDED_PROMO',
    EXTENDED_PROMO_DOLLAR = 'EXTENDED_PROMO_DOLLAR',
    EXTENDED_PROMO_PCT = 'EXTENDED_PROMO_PCT',
    EXTENDED_PROMO_DOLLAR_DDA = 'EXTENDED_PROMO_DOLLAR_DDA',
    TRADEIN_PROMO = 'TRADEIN_PROMO'
}

export enum Availability {
    AVAILABLE = 'AVAILABLE',
    PREORDER = 'PREORDER',
    BACKORDER = 'BACKORDER',
    OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export enum ShopCategory {
    DEVICE = 'DEVICE',
    ACCESSORY = 'ACCESSORIES',
    TABLET = 'TABLET',
    WATCH = 'SMART_WATCH'
}

export enum OS {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    WATCHOS = 'WATCHOS'
}

export enum BUY_CHANNEL {
    ONLINE = 'ONLINE',
    RETAIL= 'RETAIL',
    TELESALES = 'TELESALES',
    RETAIL_INSTORE = 'RETAIL_INSTORE'
}

export enum IdentityCheck {
    FAILED = 'FAILED',
    CLEARED = 'CLEARED'
}

export enum AccountType {
    LEVEL_1 = 'LEVEL_1',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_3 = 'LEVEL_3',
    IN_TRANSITION = 'IN_TRANSITION',
    BLOCKED = 'BLOCKED'
}

export enum GlobalNav {
    AUTH_TYPE_KEY = 'data-auth-type',
    AUTH_TYPE_VALUE = 'CIMA',
    AUTH_TOKEN_KEY = 'data-auth-token'
}

export enum SectionContainer {
    SECTION_COVERGAE_CHECKER = 'section-coverage-checker',
    XFINITY_STORE_SECTION = 'xfinity-store-section',
    SECTION_SAVINGS_TOUT = 'savings-tout-section'
}
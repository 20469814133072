<section #parentRef class="{{ sectionContainer }}">
    <div class="plans-container">
        <div class="plans-header">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <h2 class="header" *scRichText="rendering.fields.header"></h2>
            </ng-container>

            <div class="header-body">
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class="body"
                    [field]="rendering.fields.body"></xm-cms-text>

                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subHeader)">
                    <div class="sub-title" *scRichText="rendering.fields.subHeader"></div>
                </ng-container>
            </div>
        </div>

        <div class="mid-body">
            <ng-container *ngIf="hasPlaceholder(rendering, 'PlanFeaturesBlock')">
                <div class="swiper-container">
                    <div class="swiper-wrapper" sc-placeholder name="PlanFeaturesBlock" [rendering]="rendering"></div>
                </div>
            </ng-container>
        </div>
        
    </div>
    <div class="plans-footer">
        <div class="footer-body">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.footerHeader)">
                <div class="bottom-header" *scRichText="rendering.fields.footerHeader"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.footerBody)">
                <div class="bottom-subtext" *scRichText="rendering.fields.footerBody"></div>
            </ng-container>
            <xm-generic-link class="shop-cta" *ngIf="hasFieldOrEditable(rendering.fields.shopCta)"
                [field]="rendering.fields.shopCta" [overview]="true" [isPlansOverview]="isPlansOverview" [isPlansPage]="isPlansPage"></xm-generic-link>
            <xm-generic-link class="byod-cta" *ngIf="hasFieldOrEditable(rendering.fields.byodCta)"
                [field]="rendering.fields.byodCta" [overview]="true" [isPlansOverview]="isPlansOverview" [isPlansPage]="isPlansPage"></xm-generic-link>
        </div>
    </div>
    <div class="footer-dislaimer">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.disclaimer)">
            <div class="bottom-subtext" *scRichText="rendering.fields.disclaimer"></div>
        </ng-container>
    </div>
</section>

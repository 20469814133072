<section>
    <!-- To handle internal links -->
    <a *ngIf="logoLink.uiSref && logoLink.uiParams" class="mobile"  [attr.aria-label]="logoLink.a11yText" [uiSref]="logoLink.uiSref" [uiParams]="logoLink.uiParams" (click)="closeNav()">
        <xm-media-image [imageOptions]="logoImageOptions"></xm-media-image>
    </a>
    <a *ngIf="logoLink.uiSref && logoLink.uiParams" class="desktop"  [attr.aria-label]="logoLink.a11yText" [uiSref]="logoLink.uiSref" [uiParams]="logoLink.uiParams">
        <xm-media-image [imageOptions]="logoImageOptions"></xm-media-image>
    </a>
    <!-- To handle absolute and external links -->
    <a *ngIf="logoLink.absoluteUrl" class="mobile"  [attr.aria-label]="logoLink.a11yText" [href]="logoLink.absoluteUrl" (click)="closeNav()">
        <xm-media-image [imageOptions]="logoImageOptions"></xm-media-image>
    </a>
    <a *ngIf="logoLink.absoluteUrl" class="desktop"  [attr.aria-label]="logoLink.a11yText" [href]="logoLink.absoluteUrl">
        <xm-media-image [imageOptions]="logoImageOptions"></xm-media-image>
    </a>
</section>

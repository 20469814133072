<section #parentRef *ngIf="isOverViewPage">
    <div class="plan-section">
        <div class="plan-features">
            <xm-media-image class="image" [imageOptions]="generateImageFromCms(pageData.sectionIcon)"></xm-media-image>
            <div class="text">
                <div class="content">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.sectionHeader)">
                        <div class="plan-feature-header" *scRichText="rendering.fields.sectionHeader"></div>
                    </ng-container>
                    <div class="plan-feature-body">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.sectionSubHeader)">
                            <div class="plan-feature-sub-header" *scRichText="rendering.fields.sectionSubHeader"></div>
                        </ng-container>
                    </div>
                    <div class="feature-list">
                        <div class="feature">
                            <ng-container *ngFor="let item of rendering.fields.featureList; let i = index">
                                <div class="feature-body">
                                    <xm-media-image class="icon"
                                        [imageOptions]="generateImageFromCms(pageData.featureList[i].icon)"></xm-media-image>
                                    <ng-container
                                        *ngIf="hasFieldOrEditable(rendering.fields.featureList[i].fields.body)">
                                        <div class="description"
                                            *scRichText="rendering.fields.featureList[i].fields.body">
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isPlanPage">
    <div class="plan-feature-section">
        <div class="plan-feature">
            <div class="plan-feature-header">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.sectionSubHeader)">
                    <div class="feature-sub-header" *scRichText="rendering.fields.sectionSubHeader"></div>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.sectionHeader)">
                    <div class="feature-header" *scRichText="rendering.fields.sectionHeader"></div>
                </ng-container>
                <div class="feature-plan-price">
                    <div class="price-details">
                        <div class="price-per-month">
                            <div class="dollar-price">
                                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.ratePlanPrice)">
                                    <div class="dollar">{{ dollarSymbol }}</div>
                                    <div class="price">{{ rateplanPrice }}</div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.payFrequencyText)">
                                <div class="price-frequency-text" *scRichText="rendering.fields.payFrequencyText"></div>
                            </ng-container>
                        </div>
                        <!-- <ng-container *ngIf="hasFieldOrEditable(rendering.fields.ratePlanDisclaimer)">
                            <div class="price-disclaimer" *scRichText="rendering.fields.ratePlanDisclaimer"></div>
                        </ng-container> -->
                    </div>
                </div>
            </div>
            <div class="feature-group">
                <ng-container *ngFor="let item of rendering.fields.featureGroup; let j = index">
                    <div class="feature-group-header">
                        <div class="header" *scRichText="rendering.fields.featureGroup[j]?.fields?.groupName">
                        </div>
                        <a class="tooltip-trigger">
                            <xm-media-image class="disclaimer-icon" *ngIf="pageData.featureGroup[j]?.tooltipIcon"
                                [imageOptions]="generateImageFromCms(pageData.featureGroup[j].tooltipIcon)"></xm-media-image>
                            <span class="tooltip-content">{{pageData.featureGroup[j].tooltipText}}<xm-media-image
                                    class="beak-icon" *ngIf="pageData.featureGroup[j].bottomBeakIcon"
                                    [imageOptions]="generateImageFromCms(pageData.featureGroup[j].bottomBeakIcon)"></xm-media-image>
                            </span>
                        </a>
                    </div>

                    <div class="feature-list" [ngClass]="rendering.fields.featureGroup[j]?.fields?.groupClass?.value">
                        <div class="feature">
                            <ng-container
                                *ngFor="let item of rendering.fields.featureGroup[j]?.fields?.featureList; let i = index">
                                <div class="feature-body">
                                    <xm-media-image class="icon"
                                        [imageOptions]="generateImageFromCms(pageData.featureGroup[j].featureList[i].icon)"></xm-media-image>
                                    <ng-container
                                        *ngIf="hasFieldOrEditable(rendering.fields.featureGroup[j].fields.featureList[i].fields.body)">
                                        <div class="description"
                                            *scRichText="rendering.fields.featureGroup[j].fields.featureList[i].fields.body">
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="spotlight" [ngClass]="{ 'unl-premium': planFeatureTwo }">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.featureClass)">
                </ng-container>
            </div>
        </div>
    </div>
</section>